/*
 * SonarQube
 * Copyright (C) 2009-2018 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
import { combineReducers } from 'redux';
import users, * as fromUsers from './users/users';
import groups, * as fromGroups from './groups/groups';
import loading, * as fromLoading from './loading';
import query from './query';
import filter from './filter';
import selectedPermission from './selectedPermission';
import error from './error';

export default combineReducers({
  users,
  groups,
  loading,
  query,
  filter,
  selectedPermission,
  error
});

export const getUsers = state => fromUsers.getUsers(state.users);

export const getGroups = state => fromGroups.getGroups(state.groups);

export const isLoading = state => fromLoading.isLoading(state.loading);

export const getQuery = state => state.query;

export const getFilter = state => state.filter;

export const getSelectedPermission = state => state.selectedPermission;

export const getError = state => state.error;
