/*
 * SonarQube
 * Copyright (C) 2009-2018 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
export const TYPE_STRING = 'STRING';
export const TYPE_TEXT = 'TEXT';
export const TYPE_PASSWORD = 'PASSWORD';
export const TYPE_BOOLEAN = 'BOOLEAN';
export const TYPE_FLOAT = 'FLOAT';
export const TYPE_INTEGER = 'INTEGER';
export const TYPE_LONG = 'LONG';
export const TYPE_SINGLE_SELECT_LIST = 'SINGLE_SELECT_LIST';
export const TYPE_PROPERTY_SET = 'PROPERTY_SET';
